import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { MapPin, Phone, Mail, Globe, ArrowLeft } from 'lucide-react';
import { supabase } from '../../lib/supabase/client';
import { MetaTags } from '../../components/seo/MetaTags';
import type { Service } from '../../types';

export function ServiceDetailsPage() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [service, setService] = useState<Service | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchService() {
      if (!id) return;

      try {
        const { data, error: err } = await supabase
          .from('services')
          .select(`
            *,
            images:service_images(url, position),
            provider:profiles!inner(
              business_name,
              is_verified
            ),
            airports:service_airports!inner(
              airport:airports!inner(
                code,
                name,
                country
              )
            )
          `)
          .eq('id', id)
          .single();

        if (err) throw err;

        if (data) {
          // Get the airport that matches the referrer URL, or default to first airport
          const referrerAirport = location.state?.from?.match(/\/airport\/([a-z]+)/)?.[1];
          const matchingAirport = data.airports.find(
            a => a.airport.code.toLowerCase() === referrerAirport
          ) || data.airports[0];

          setService({
            id: data.id,
            title: data.title,
            description: data.description,
            price: Number(data.price),
            airportId: matchingAirport.airport.code,
            airportName: matchingAirport.airport.name,
            category: data.category,
            contactEmail: data.contact_email,
            contactPhone: data.contact_phone,
            contactAddress: data.contact_address,
            contactWebsite: data.contact_website,
            contactForPrice: data.contact_for_price,
            images: data.images?.map(img => img.url) || [],
            provider: {
              name: data.provider.business_name,
              rating: 4.5, // Placeholder
              reviews: 10, // Placeholder
              image: '', // Using initials instead
              isVerified: data.provider.is_verified
            }
          });
        }
      } catch (err) {
        console.error('Error fetching service:', err);
        setError('Failed to load service details');
      } finally {
        setIsLoading(false);
      }
    }

    fetchService();
  }, [id, location.state?.from]);

  const handleBack = () => {
    // If we came from an airport page, go back to that page
    if (location.state?.from?.includes('/airport/')) {
      navigate(-1);
    } else if (service?.airportId) {
      // Otherwise, go to the service's airport page
      navigate(`/airport/${service.airportId.toLowerCase()}`);
    } else {
      // Fallback to home page
      navigate('/');
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-gray-600">Loading service details...</div>
      </div>
    );
  }

  if (error || !service) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-600">{error || 'Service not found'}</div>
      </div>
    );
  }

  return (
    <>
      <MetaTags
        title={`${service.title} at ${service.airportName}`}
        description={service.description}
      />

      <main className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-6">
            <button
              onClick={handleBack}
              className="inline-flex items-center text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft size={20} className="mr-2" />
              Back to services
            </button>
          </div>

          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            {/* Image Gallery */}
            <div className="relative h-96">
              {service.images && service.images.length > 0 ? (
                <img
                  src={service.images[0]}
                  alt={service.title}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                  <span className="text-gray-400">No image available</span>
                </div>
              )}
            </div>

            <div className="p-8">
              {/* Header */}
              <div className="mb-8">
                <div className="flex items-center gap-2 text-sm text-gray-600 mb-2">
                  <MapPin size={16} />
                  <span>{service.airportName}</span>
                </div>
                <h1 className="text-3xl font-bold text-gray-900 mb-4">
                  {service.title}
                </h1>
                <div className="text-xl font-semibold text-gray-900">
                  {service.contactForPrice ? (
                    'Contact for price'
                  ) : (
                    `$${service.price.toFixed(2)}`
                  )}
                </div>
              </div>

              {/* Description */}
              <div className="prose max-w-none mb-8">
                <h2 className="text-xl font-semibold mb-4">About this service</h2>
                <p className="text-gray-600 whitespace-pre-line">
                  {service.description}
                </p>
              </div>

              {/* Contact Information */}
              <div className="border-t pt-8">
                <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
                <div className="space-y-4">
                  {service.contactPhone && (
                    <div className="flex items-center gap-3">
                      <Phone className="text-gray-400" size={20} />
                      <a 
                        href={`tel:${service.contactPhone}`}
                        className="text-gray-600 hover:text-gray-900"
                      >
                        {service.contactPhone}
                      </a>
                    </div>
                  )}
                  
                  {service.contactEmail && (
                    <div className="flex items-center gap-3">
                      <Mail className="text-gray-400" size={20} />
                      <a 
                        href={`mailto:${service.contactEmail}`}
                        className="text-gray-600 hover:text-gray-900"
                      >
                        {service.contactEmail}
                      </a>
                    </div>
                  )}

                  {service.contactWebsite && (
                    <div className="flex items-center gap-3">
                      <Globe className="text-gray-400" size={20} />
                      <a 
                        href={service.contactWebsite}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 hover:text-gray-900"
                      >
                        Visit website
                      </a>
                    </div>
                  )}

                  {service.contactAddress && (
                    <div className="flex items-start gap-3">
                      <MapPin className="text-gray-400 mt-1" size={20} />
                      <span className="text-gray-600">
                        {service.contactAddress}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}