import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { SearchProvider } from './contexts/SearchContext';
import { Header } from './components/layout/Header/Header';
import { Footer } from './components/layout/Footer/Footer';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { SignInPage } from './pages/auth/SignIn';
import { SignUpPage } from './pages/auth/SignUp';
import { AirportPage } from './pages/airport/[iata]';
import { ServiceDetailsPage } from './pages/services/[id]';
import { PricingPage } from './pages/pricing';
import { TrackingPage } from './pages/tracking';
import { DashboardLayout } from './pages/dashboard/DashboardLayout';
import { DashboardOverviewPage } from './pages/dashboard/overview';
import { ServicesPage } from './pages/dashboard/services';
import { AnalyticsPage } from './pages/dashboard/analytics';
import { BookingsPage } from './pages/dashboard/bookings';
import { SettingsPage } from './pages/dashboard/settings';
import { PrivacyPage } from './pages/legal/Privacy';
import { TermsPage } from './pages/legal/Terms';
import { CookiesPage } from './pages/legal/Cookies';
import { BlogPage } from './pages/blog';
import { BlogPostPage } from './pages/blog/[slug]';
import { NotFoundPage } from './pages/NotFound';
import { majorAirports } from './data/airports/majorAirports';

export default function App() {
  const defaultAirport = majorAirports[0];
  const [selectedAirport, setSelectedAirport] = useState(defaultAirport.id);

  const handleAirportSelect = (airportId: string) => {
    setSelectedAirport(airportId);
  };

  return (
    <AuthProvider>
      <SearchProvider>
        <BrowserRouter>
          <div className="min-h-screen bg-gray-50 flex flex-col">
            <Header 
              selectedAirport={selectedAirport}
              onAirportSelect={handleAirportSelect}
            />
            
            <Routes>
              {/* Default route redirects to JFK */}
              <Route path="/" element={<Navigate to="/airport/jfk" replace />} />
              
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/tracking" element={<TrackingPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/cookies" element={<CookiesPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:slug" element={<BlogPostPage />} />
              <Route path="/services/:id" element={<ServiceDetailsPage />} />
              <Route path="/airport/:iata" element={<AirportPage onAirportSelect={handleAirportSelect} />} />
              
              {/* Protected Dashboard Routes */}
              <Route path="/dashboard" element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>}>
                <Route index element={<DashboardOverviewPage />} />
                <Route path="services" element={<ServicesPage />} />
                <Route path="analytics" element={<AnalyticsPage />} />
                <Route path="bookings" element={<BookingsPage />} />
                <Route path="settings" element={<SettingsPage />} />
              </Route>

              {/* 404 catch-all route */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>

            <Footer />
          </div>
        </BrowserRouter>
      </SearchProvider>
    </AuthProvider>
  );
}