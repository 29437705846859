import React from 'react';
import { categories, type CategoryId } from '../types';

interface CategoryFilterProps {
  selected: CategoryId;
  onSelect: (category: CategoryId) => void;
}

export function CategoryFilter({ selected, onSelect }: CategoryFilterProps) {
  return (
    <div className="relative max-w-7xl mx-auto">
      <div className="flex flex-row md:flex md:justify-center gap-2 md:gap-4 overflow-x-auto md:overflow-visible pb-4 px-4 sm:px-6 scrollbar-hide snap-x snap-mandatory md:snap-none scroll-smooth">
        {categories.map(({ id, name, icon: Icon, description }) => {
          const isSelected = selected === id;
          return (
            <button
              key={id}
              onClick={() => onSelect(id)}
              className={`
                flex flex-col items-center justify-center gap-1 md:gap-2
                px-3 py-2 md:px-4 md:py-3 rounded-lg transition-all duration-200
                min-w-[90px] md:min-w-[130px] h-[70px] md:h-[100px] 
                flex-shrink-0 md:flex-shrink
                snap-start md:snap-align-none hover:scale-105
                ${isSelected
                  ? 'bg-primary-500 text-white shadow-lg scale-105'
                  : 'bg-white text-gray-600 hover:bg-gray-50 hover:shadow-md'
                }
              `}
              title={description}
            >
              <div className={`
                flex items-center justify-center
                w-5 h-5 md:w-8 md:h-8
                ${isSelected ? 'text-white' : 'text-gray-500'}
              `}>
                <Icon className="w-full h-full" />
              </div>
              <span className="text-xs md:text-sm font-medium text-center">
                {name}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
}