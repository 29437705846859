import React from 'react';
import { ServiceIllustration } from './ServiceIllustration';

export function Banner() {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-blue-100 py-6 sm:py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-6 sm:mb-12">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-3 sm:mb-4 px-4">
            Everything You Need, Right Where You Land
          </h2>
          <p className="text-base sm:text-xl text-gray-600 max-w-2xl mx-auto px-4">
            Trusted by Travelers, Backed by Excellence
          </p>
        </div>
        <ServiceIllustration />
      </div>
    </div>
  );
}