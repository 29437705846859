import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserMenu } from './UserMenu';
import { useAuthContext } from '../../../contexts/AuthContext';

export function Navigation() {
  const { user } = useAuthContext();
  const location = useLocation();

  if (user) {
    return <UserMenu />;
  }

  return (
    <nav className="flex items-center gap-4">
      <Link 
        to="/tracking" 
        className={`text-gray-600 hover:text-gray-900 ${
          location.pathname === '/tracking' ? 'font-medium text-gray-900' : ''
        }`}
      >
        Track Aircraft
      </Link>
      <Link 
        to="/pricing" 
        className={`text-gray-600 hover:text-gray-900 ${
          location.pathname === '/pricing' ? 'font-medium text-gray-900' : ''
        }`}
      >
        Pricing
      </Link>
      <Link 
        to="/signin" 
        className={`px-4 py-2 text-gray-600 hover:text-gray-900 ${
          location.pathname === '/signin' ? 'font-medium text-gray-900' : ''
        }`}
      >
        Sign in
      </Link>
      <Link
        to="/signup"
        className="px-4 py-2 bg-primary-500 hover:bg-primary-600 text-white font-semibold rounded-lg transition-colors"
      >
        List Your Service
      </Link>
    </nav>
  );
}