import React from 'react';
import { Lock } from 'lucide-react';

export function AnalyticsPage() {
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Analytics Overview</h1>
        <div className="text-xs font-medium text-primary-600 bg-primary-50 px-3 py-1.5 rounded-full">
          Coming Soon
        </div>
      </div>

      {/* Metrics Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="relative bg-white rounded-xl shadow-sm p-6 overflow-hidden">
            <div className="absolute inset-0 bg-white/80 backdrop-blur-[2px] rounded-xl flex flex-col items-center justify-center z-10">
              <Lock className="w-5 h-5 text-gray-400 mb-2" />
              <span className="text-sm text-gray-500 font-medium">Coming Soon</span>
            </div>
            <div className="animate-pulse">
              <div className="h-8 w-8 bg-gray-200 rounded-lg mb-4" />
              <div className="h-4 w-24 bg-gray-200 rounded mb-2" />
              <div className="h-6 w-16 bg-gray-200 rounded" />
            </div>
          </div>
        ))}
      </div>

      {/* Chart */}
      <div className="relative bg-white rounded-xl shadow-sm p-6 mb-8 overflow-hidden">
        <div className="absolute inset-0 bg-white/80 backdrop-blur-[2px] rounded-xl flex flex-col items-center justify-center z-10">
          <Lock className="w-6 h-6 text-gray-400 mb-2" />
          <span className="text-base text-gray-500 font-medium">Analytics Coming Soon</span>
          <p className="text-sm text-gray-400 mt-2 text-center max-w-md">
            Get detailed insights into your service performance, customer engagement, and revenue trends
          </p>
        </div>
        <div className="animate-pulse">
          <div className="flex justify-between mb-8">
            <div className="h-6 w-32 bg-gray-200 rounded" />
            <div className="h-6 w-24 bg-gray-200 rounded" />
          </div>
          <div className="h-64 bg-gray-200 rounded" />
        </div>
      </div>

      {/* Additional Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="relative bg-white rounded-xl shadow-sm p-6 overflow-hidden">
          <div className="absolute inset-0 bg-white/80 backdrop-blur-[2px] rounded-xl flex flex-col items-center justify-center z-10">
            <Lock className="w-5 h-5 text-gray-400 mb-2" />
            <span className="text-sm text-gray-500 font-medium">Coming Soon</span>
          </div>
          <div className="animate-pulse">
            <div className="h-6 w-48 bg-gray-200 rounded mb-6" />
            <div className="space-y-4">
              <div className="h-8 bg-gray-200 rounded" />
              <div className="h-8 bg-gray-200 rounded" />
              <div className="h-8 bg-gray-200 rounded" />
            </div>
          </div>
        </div>

        <div className="relative bg-white rounded-xl shadow-sm p-6 overflow-hidden">
          <div className="absolute inset-0 bg-white/80 backdrop-blur-[2px] rounded-xl flex flex-col items-center justify-center z-10">
            <Lock className="w-5 h-5 text-gray-400 mb-2" />
            <span className="text-sm text-gray-500 font-medium">Coming Soon</span>
          </div>
          <div className="animate-pulse">
            <div className="h-6 w-48 bg-gray-200 rounded mb-6" />
            <div className="flex items-center justify-center">
              <div className="w-48 h-48 rounded-full bg-gray-200" />
            </div>
          </div>
        </div>
      </div>

      {/* Upgrade Notice */}
      <div className="mt-8 bg-primary-50 rounded-xl p-8 text-center">
        <h3 className="text-lg font-semibold text-primary-900 mb-2">
          Want early access to analytics?
        </h3>
        <p className="text-primary-700 mb-6">
          Join our waitlist to be among the first to try our advanced analytics features.
        </p>
        <button className="px-6 py-3 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors">
          Join Waitlist
        </button>
      </div>
    </div>
  );
}