import React, { useState } from 'react';
import { Plane, Globe, Info, Shield, MapPin } from 'lucide-react';
import { MetaTags } from '../../components/seo/MetaTags';
import { StructuredData } from '../../components/seo/StructuredData';

export function TrackingPage() {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showGlobalMap, setShowGlobalMap] = useState(false);

  const fetchWithRetry = async (url: string, retries = 3): Promise<Response> => {
    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Accept': 'application/json, text/plain, */*',
          },
          mode: 'cors',
          credentials: 'omit'
        });
        
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Aircraft not found with this tail number.");
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const text = await response.text();
        if (!text || text.trim().length === 0) {
          throw new Error("No data received for this tail number.");
        }
        
        return response;
      } catch (err) {
        console.warn(`Attempt ${i + 1} failed:`, err);
        
        if (i === retries - 1) {
          if (err instanceof TypeError && err.message.includes('Failed to fetch')) {
            throw new Error("Unable to connect to tracking service. Please check your internet connection and try again.");
          }
          throw err;
        }
        
        // Wait before retrying (exponential backoff)
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000));
      }
    }
    throw new Error("Failed to fetch aircraft data after multiple attempts.");
  };

  const handleSearch = async () => {
    const tailNumber = (document.getElementById('tailNumber') as HTMLInputElement)?.value?.trim();
    const errorElement = document.getElementById('error');
    const embedIframe = document.getElementById('embed') as HTMLIFrameElement;
    const placeholder = document.getElementById('placeholder');

    // Reset previous state
    setError(null);
    if (errorElement) errorElement.style.display = "none";
    if (embedIframe) embedIframe.style.display = "none";
    if (placeholder) placeholder.style.display = "none";

    if (!tailNumber) {
      const message = "Please enter a valid tail number.";
      setError(message);
      if (errorElement) {
        errorElement.textContent = message;
        errorElement.style.display = "block";
      }
      return;
    }

    setIsLoading(true);
    if (placeholder) placeholder.style.display = "block";

    try {
      // Validate tail number format
      if (!/^[A-Za-z0-9-]+$/.test(tailNumber)) {
        throw new Error("Invalid tail number format. Please use only letters, numbers, and hyphens.");
      }

      // Fetch ICAO hex code with retry mechanism
      const response = await fetchWithRetry(
        `https://hexdb.io/reg-hex?reg=${encodeURIComponent(tailNumber.toLowerCase())}`
      );

      // Get the hex code and convert to lowercase for consistency
      const text = await response.text();
      const hex = text.trim().toLowerCase(); // Extracts the hex code from the response body

      if (!hex) {
        throw new Error("No ICAO hex code found for this tail number.");
      }

      // Update iframe with the hex code
      if (embedIframe) {
        embedIframe.src = `https://adsb.lol/?icao=${hex}`;
        embedIframe.style.display = "block";
        setShowGlobalMap(true);
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : "Failed to fetch aircraft data. Please try again.";
      setError(message);
      console.warn('Aircraft tracking error:', { error: err, tailNumber });
      
      if (errorElement) {
        errorElement.textContent = message;
        errorElement.style.display = "block";
      }

      // Open the global map even if there's an error
      if (embedIframe) {
        embedIframe.src = 'https://adsb.lol';
        embedIframe.style.display = "block";
        setShowGlobalMap(true);
      }
    } finally {
      setIsLoading(false);
      if (placeholder) placeholder.style.display = "none";
    }
  };

  const handleShowGlobalMap = () => {
    const embedIframe = document.getElementById('embed') as HTMLIFrameElement;
    if (embedIframe) {
      embedIframe.src = 'https://adsb.lol';
      embedIframe.style.display = "block";
      setShowGlobalMap(true);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <MetaTags
        title="Track Blocked Aircraft & Live Flight Tracking | AirPortal"
        description="Track any aircraft in real-time, including blocked planes and private jets. Advanced tracking system for monitoring aircraft registration, position, altitude, speed, and route data. Free global coverage."
        keywords={[
          'aircraft tracking',
          'blocked aircraft tracking',
          'track blocked planes',
          'private jet tracking',
          'flight tracker',
          'live flight tracking',
          'real-time aircraft position',
          'aviation tracking',
          'tail number lookup',
          'global air traffic',
          'flight monitoring',
          'blocked tail numbers'
        ]}
      />

      <StructuredData 
        type="WebApplication" 
        data={{
          name: "AirPortal Aircraft Tracking",
          applicationCategory: "Transportation/Aviation",
          operatingSystem: "Any",
          offers: {
            price: "0",
            priceCurrency: "USD"
          }
        }} 
      />

      <main className="min-h-screen bg-gradient-to-br from-primary-50 via-blue-50 to-primary-50">
        {/* Hero Section */}
        <div className="relative overflow-hidden bg-gradient-to-b from-primary-600 to-primary-800 text-white">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1436491865332-7a61a109cc05?auto=format&fit=crop&w=2000')] bg-cover bg-center opacity-10"></div>
          <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            <div className="text-center">
              <div className="inline-flex p-3 bg-white/10 backdrop-blur-sm rounded-2xl mb-8">
                <Plane className="h-12 w-12" />
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Advanced Aircraft Tracking
              </h1>
              <p className="text-xl text-primary-100 max-w-2xl mx-auto mb-12">
                Track any aircraft in real-time, including blocked planes and private jets.
              </p>

              {/* Search Box */}
              <div className="max-w-2xl mx-auto">
                <div className="relative flex shadow-lg rounded-lg overflow-hidden mb-4">
                  <input
                    type="text"
                    id="tailNumber"
                    placeholder="Enter Tail Number (e.g., N154EA)"
                    onKeyPress={handleKeyPress}
                    className="flex-1 px-6 py-4 text-gray-900 placeholder-gray-500 bg-white focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    disabled={isLoading}
                  />
                  <button
                    onClick={handleSearch}
                    disabled={isLoading}
                    className="px-8 bg-primary-500 hover:bg-primary-600 text-white font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isLoading ? 'Loading...' : 'Track'}
                  </button>
                </div>

                {/* Show Global Map Button */}
                <button
                  onClick={handleShowGlobalMap}
                  disabled={isLoading}
                  className="inline-flex items-center gap-2 px-6 py-3 bg-white/10 hover:bg-white/20 backdrop-blur-sm rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <Globe className="h-5 w-5" />
                  <span>Show Global Map</span>
                </button>

                <p id="error" className="mt-3 text-red-300 text-sm hidden"></p>
                <p id="placeholder" className="mt-3 text-primary-100 text-sm hidden">
                  Loading map...
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Map Container */}
          <iframe
            id="embed"
            title="Aircraft Tracking Map"
            className="w-full h-[600px] rounded-xl shadow-2xl border-4 border-white hidden"
            sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-forms allow-modals"
            allow="geolocation; fullscreen; clipboard-write; web-share"
            loading="lazy"
          />

          {/* Features Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
            <div className="bg-white/80 backdrop-blur-sm p-8 rounded-xl shadow-sm border border-white/20">
              <div className="p-3 bg-primary-50 rounded-xl w-fit mb-6">
                <Shield className="h-6 w-6 text-primary-500" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Track Blocked Aircraft
              </h3>
              <p className="text-gray-600">
                Access comprehensive tracking data for blocked and private aircraft while respecting privacy protocols.
              </p>
            </div>

            <div className="bg-white/80 backdrop-blur-sm p-8 rounded-xl shadow-sm border border-white/20">
              <div className="p-3 bg-primary-50 rounded-xl w-fit mb-6">
                <Globe className="h-6 w-6 text-primary-500" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Global Coverage
              </h3>
              <p className="text-gray-600">
                Track aircraft worldwide with our extensive network of receivers and data sources.
              </p>
            </div>

            <div className="bg-white/80 backdrop-blur-sm p-8 rounded-xl shadow-sm border border-white/20">
              <div className="p-3 bg-primary-50 rounded-xl w-fit mb-6">
                <MapPin className="h-6 w-6 text-primary-500" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Real-Time Updates
              </h3>
              <p className="text-gray-600">
                Get live position updates, altitude data, and route information for any aircraft.
              </p>
            </div>
          </div>

          {/* Tips Section */}
          <div className="mt-16">
            <div className="bg-white/80 backdrop-blur-sm rounded-xl p-8 border border-white/20">
              <h3 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center gap-3">
                <Info className="h-6 w-6 text-primary-500" />
                Tracking Tips
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <ul className="space-y-4">
                  <li className="flex items-start gap-3">
                    <div className="p-1 bg-primary-50 rounded-full mt-1.5">
                      <div className="w-1.5 h-1.5 bg-primary-500 rounded-full"></div>
                    </div>
                    <p className="text-gray-600">
                      Enter the complete tail number (e.g., N154EA) for most accurate results
                    </p>
                  </li>
                  <li className="flex items-start gap-3">
                    <div className="p-1 bg-primary-50 rounded-full mt-1.5">
                      <div className="w-1.5 h-1.5 bg-primary-500 rounded-full"></div>
                    </div>
                    <p className="text-gray-600">
                      For blocked aircraft, you may see limited or delayed information
                    </p>
                  </li>
                </ul>
                <ul className="space-y-4">
                  <li className="flex items-start gap-3">
                    <div className="p-1 bg-primary-50 rounded-full mt-1.5">
                      <div className="w-1.5 h-1.5 bg-primary-500 rounded-full"></div>
                    </div>
                    <p className="text-gray-600">
                      Use the global view to see all active flights in your area of interest
                    </p>
                  </li>
                  <li className="flex items-start gap-3">
                    <div className="p-1 bg-primary-50 rounded-full mt-1.5">
                      <div className="w-1.5 h-1.5 bg-primary-500 rounded-full"></div>
                    </div>
                    <p className="text-gray-600">
                      Some aircraft may temporarily disappear from tracking due to coverage areas
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}