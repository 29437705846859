import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import { MetaTags } from '../../components/seo/MetaTags';
import { StructuredData } from '../../components/seo/StructuredData';
import { getAirport } from '../../data/airports/airportData';
import { CategoryFilter } from '../../components/CategoryFilter';
import { ServiceList } from '../../components/services/ServiceList';
import { Banner } from '../../components/layout/Banner/Banner';
import { FAQ } from '../../components/seo/FAQ';
import { Testimonials } from '../../components/seo/Testimonials';
import { BlogPreview } from '../../components/blog/BlogPreview';
import { useServices } from '../../hooks/services/useServices';
import { useBlogPosts } from '../../hooks/blog/useBlogPosts';
import type { CategoryId } from '../../types';
import type { Airport } from '../../data/airports/types';

interface AirportPageProps {
  onAirportSelect: (airportId: string) => void;
}

const DEFAULT_AIRPORT = 'jfk';

export function AirportPage({ onAirportSelect }: AirportPageProps) {
  const { iata } = useParams();
  const [selectedCategory, setSelectedCategory] = useState<CategoryId>('ground-transport');
  const [airport, setAirport] = useState<Airport | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  const { services, isLoading: servicesLoading, error: servicesError } = useServices(iata || DEFAULT_AIRPORT);
  const { posts, isLoading: postsLoading } = useBlogPosts();
  
  useEffect(() => {
    async function loadAirport() {
      setIsLoading(true);
      setError(null);

      try {
        // Use default airport if no IATA code provided
        const airportCode = iata || DEFAULT_AIRPORT;
        const airportData = await getAirport(airportCode);
        
        if (airportData) {
          setAirport(airportData);
          onAirportSelect(airportData.id);
        } else {
          setError('Airport not found');
        }
      } catch (err) {
        console.error('Error loading airport:', err);
        setError('Failed to load airport information');
      } finally {
        setIsLoading(false);
      }
    }
    
    loadAirport();
  }, [iata, onAirportSelect]);

  // Show loading state
  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-gray-600">Loading airport information...</div>
      </div>
    );
  }

  // Handle errors
  if (error || !airport) {
    // If no IATA code provided, redirect to default airport
    if (!iata) {
      return <Navigate to={`/airport/${DEFAULT_AIRPORT}`} replace />;
    }

    return (
      <div className="min-h-screen flex flex-col items-center justify-center gap-4">
        <div className="text-red-600">{error || 'Airport not found'}</div>
        <Link 
          to={`/airport/${DEFAULT_AIRPORT}`}
          className="text-primary-500 hover:text-primary-600 font-medium"
        >
          Return to JFK Airport
        </Link>
      </div>
    );
  }

  const filteredServices = services.filter(
    service => selectedCategory === 'other' 
      ? !['ground-transport', 'catering', 'stays', 'maintenance', 'local', 'charter', 'fbos'].includes(service.category) 
      : service.category === selectedCategory
  );

  // Get the 3 most recent blog posts
  const recentPosts = posts.slice(0, 3);

  // Generate SEO keywords based on airport and services
  const keywords = [
    airport.iata,
    airport.name,
    airport.country,
    'airport services',
    'aviation services',
    ...services.map(s => s.category),
    'ground transportation',
    'aircraft maintenance',
    'catering services',
    'FBO services'
  ];

  // Generate breadcrumb data for structured data
  const breadcrumbData = {
    items: [
      { name: 'Home', url: '/' },
      { name: 'Airports', url: '/' },
      { name: `${airport.name} (${airport.iata})`, url: `/airport/${airport.iata.toLowerCase()}` }
    ]
  };

  // Generate place data for structured data
  const placeData = {
    '@context': 'https://schema.org',
    '@type': 'Airport',
    name: airport.name,
    iataCode: airport.iata,
    address: {
      '@type': 'PostalAddress',
      addressCountry: airport.country
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: airport.latitude,
      longitude: airport.longitude
    }
  };

  return (
    <>
      <MetaTags
        title={`${airport.name} (${airport.iata}) - Airport Services & Information`}
        description={`Find and book trusted aviation services at ${airport.name}. Ground transportation, maintenance, catering, and more at ${airport.iata} Airport. Latest updates and service information available.`}
        canonicalUrl={`${window.location.origin}/airport/${airport.iata.toLowerCase()}`}
        keywords={keywords}
      />

      <StructuredData type="BreadcrumbList" data={breadcrumbData} />
      <StructuredData type="Place" data={placeData} />

      <main className="min-h-screen">
        <Banner />
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="mb-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-2">
              Services at {airport.name} ({airport.iata})
            </h1>
            <p className="text-gray-600">
              Find and book trusted aviation services at {airport.iata} Airport. 
              From ground transportation to aircraft maintenance, we connect you with verified service providers.
            </p>
          </div>

          <CategoryFilter 
            selected={selectedCategory} 
            onSelect={setSelectedCategory} 
          />

          <ServiceList 
            services={filteredServices}
            isLoading={servicesLoading}
            error={servicesError}
          />
        </div>

        <Testimonials />
        <FAQ />
        
        {!postsLoading && recentPosts.length > 0 && (
          <BlogPreview posts={recentPosts} />
        )}
      </main>
    </>
  );
}