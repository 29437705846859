import React from 'react';
import { MetaTags } from '../../components/seo/MetaTags';

export function CookiesPage() {
  return (
    <>
      <MetaTags
        title="Cookie Policy - AirPortal"
        description="Learn about how AirPortal uses cookies and similar technologies."
      />

      <main className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Cookie Policy</h1>
          
          <div className="bg-white rounded-xl shadow-sm p-8 space-y-8">
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">What Are Cookies</h2>
              <p className="text-gray-600">
                Cookies are small text files that are placed on your computer or mobile device when you visit a website. They are widely used to make websites work more efficiently and provide useful information to website owners.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">How We Use Cookies</h2>
              <div className="space-y-4">
                <p className="text-gray-600">
                  We use cookies for the following purposes:
                </p>
                <ul className="list-disc list-inside text-gray-600 ml-4 space-y-2">
                  <li>To enable certain functions of the Service</li>
                  <li>To provide analytics</li>
                  <li>To store your preferences</li>
                  <li>To enable advertisements delivery, including behavioral advertising</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Types of Cookies We Use</h2>
              <div className="space-y-6">
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">Essential Cookies</h3>
                  <p className="text-gray-600">
                    These cookies are necessary for the website to function properly. They enable core functionality such as security, network management, and accessibility.
                  </p>
                </div>

                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">Analytics Cookies</h3>
                  <p className="text-gray-600">
                    These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously.
                  </p>
                </div>

                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">Functionality Cookies</h3>
                  <p className="text-gray-600">
                    These cookies enable the website to provide enhanced functionality and personalization by remembering choices you make.
                  </p>
                </div>

                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">Advertising Cookies</h3>
                  <p className="text-gray-600">
                    These cookies are used to deliver advertisements more relevant to you and your interests.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Your Cookie Choices</h2>
              <div className="space-y-4">
                <p className="text-gray-600">
                  Most web browsers allow you to control cookies through their settings preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience.
                </p>
                <p className="text-gray-600">
                  To manage your cookie preferences:
                </p>
                <ul className="list-disc list-inside text-gray-600 ml-4 space-y-2">
                  <li>Access your browser settings</li>
                  <li>Look for a "Privacy" or "Security" section</li>
                  <li>Find the section dealing with cookies</li>
                  <li>Choose your preferred cookie settings</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Third-Party Cookies</h2>
              <p className="text-gray-600">
                In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Service and deliver advertisements on and through the Service.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Contact Us</h2>
              <p className="text-gray-600">
                If you have questions about our use of cookies or other technologies, please contact us at privacy@airportal.com.
              </p>
            </section>

            <div className="text-sm text-gray-500 pt-8 border-t">
              Last updated: January 7, 2025
            </div>
          </div>
        </div>
      </main>
    </>
  );
}