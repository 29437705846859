import React from 'react';
import { Edit2, Trash2, MapPin, Phone, Mail, Globe2 } from 'lucide-react';
import { useServiceImages } from '../../../../hooks/services/useServiceImages';
import type { Service } from '../../../../types';

interface ServiceCardProps {
  service: Service;
  onEdit: () => void;
  onDelete: () => void;
}

export function ServiceCard({ service, onEdit, onDelete }: ServiceCardProps) {
  const { images } = useServiceImages(service.id);

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
      <div className="relative h-48">
        <img
          src={images[0] || 'https://images.unsplash.com/photo-1436491865332-7a61a109cc05'}
          alt={service.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute top-4 right-4 bg-white px-3 py-1 rounded-full text-sm font-semibold">
          {service.contactForPrice ? 'Contact for price' : `$${service.price}`}
        </div>
      </div>
      
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2">{service.title}</h3>
        <p className="text-gray-600 text-sm mb-4">{service.description}</p>
        
        <div className="space-y-2 mb-4">
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <MapPin size={16} />
            <span>{service.airportId}</span>
          </div>
          
          {service.contactPhone && (
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <Phone size={16} />
              <span>{service.contactPhone}</span>
            </div>
          )}
          
          {service.contactEmail && (
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <Mail size={16} />
              <span>{service.contactEmail}</span>
            </div>
          )}
          
          {service.contactWebsite && (
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <Globe2 size={16} />
              <a 
                href={service.contactWebsite}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-primary-500"
              >
                Website
              </a>
            </div>
          )}
        </div>
        
        <div className="flex justify-end gap-2">
          <button 
            onClick={onEdit}
            className="p-2 text-gray-600 hover:text-primary-500"
          >
            <Edit2 size={18} />
          </button>
          <button 
            onClick={onDelete}
            className="p-2 text-gray-600 hover:text-red-500"
          >
            <Trash2 size={18} />
          </button>
        </div>
      </div>
    </div>
  );
}