import type { Airport } from './types';
import { majorAirports } from './majorAirports';

// In-memory cache for loaded airports
let fullAirportList: Airport[] | null = null;

// Validate required airport fields
function isValidAirport(airport: Partial<Airport>): airport is Airport {
  return Boolean(
    airport &&
    airport.id &&
    airport.iata &&
    airport.name
  );
}

export async function getAirport(iata: string): Promise<Airport | undefined> {
  // First check major airports for common cases
  const majorAirport = majorAirports.find(
    a => a.iata.toLowerCase() === iata.toLowerCase()
  );
  
  if (majorAirport && isValidAirport(majorAirport)) {
    return majorAirport;
  }

  // Load full airport list if needed
  if (!fullAirportList) {
    try {
      const { airports } = await import('./fullAirportList');
      // Filter out invalid airports
      fullAirportList = airports.filter(isValidAirport);
    } catch (error) {
      console.error('Failed to load full airport list:', error);
      return undefined;
    }
  }

  const airport = fullAirportList.find(
    a => a.iata.toLowerCase() === iata.toLowerCase()
  );

  return airport && isValidAirport(airport) ? airport : undefined;
}