import React from 'react';
import { Plus } from 'lucide-react';

interface EmptyStateProps {
  onAdd: () => void;
}

export function EmptyState({ onAdd }: EmptyStateProps) {
  return (
    <div className="text-center py-12 px-4 border-2 border-dashed border-gray-300 rounded-lg">
      <div className="flex justify-center mb-4">
        <div className="p-3 bg-primary-50 rounded-full">
          <Plus size={24} className="text-primary-500" />
        </div>
      </div>
      <h3 className="text-lg font-medium text-gray-900 mb-1">No services yet</h3>
      <p className="text-gray-500 mb-4">Get started by creating your first service listing</p>
      <button 
        onClick={onAdd}
        className="inline-flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600"
      >
        <Plus size={20} />
        Add Service
      </button>
    </div>
  );
}