import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { MetaTags } from '../../components/seo/MetaTags';
import { useBlogPost } from '../../hooks/blog/useBlogPost';
import { formatDate } from '../../utils/date';

export function BlogPostPage() {
  const { slug } = useParams();
  const { post, isLoading, error } = useBlogPost(slug);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="animate-pulse space-y-8">
            <div className="h-8 bg-gray-200 rounded w-3/4" />
            <div className="h-4 bg-gray-200 rounded w-1/4" />
            <div className="aspect-video bg-gray-200 rounded" />
            <div className="space-y-4">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="h-4 bg-gray-200 rounded w-full" />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error || 'Post not found'}</p>
          <Link to="/blog" className="text-primary-500 hover:text-primary-600">
            Return to blog
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <MetaTags
        title={`${post.title} - AirPortal Blog`}
        description={post.excerpt}
        image={post.cover_image}
        type="article"
      />

      <main className="min-h-screen bg-gray-50">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <Link
            to="/blog"
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-8"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to blog
          </Link>

          <article>
            <header className="mb-8">
              <h1 className="text-4xl font-bold text-gray-900 mb-4">
                {post.title}
              </h1>
              <div className="flex items-center gap-4 text-gray-500">
                <time dateTime={post.published_at}>
                  {formatDate(post.published_at)}
                </time>
                <span>·</span>
                <span>{post.author}</span>
              </div>
            </header>

            {post.cover_image && (
              <div className="mb-8">
                <img
                  src={post.cover_image}
                  alt={post.title}
                  className="w-full rounded-xl"
                />
              </div>
            )}

            <div 
              className="prose prose-lg max-w-none"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </article>
        </div>
      </main>
    </>
  );
}