import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { supabase } from '../../../../lib/supabase/client';

interface Airport {
  code: string;
  name: string;
  city: string;
  country: string;
}

interface AirportSearchProps {
  selectedAirports: string[];
  onSelect: (code: string) => void;
  maxSelections: number;
}

export function AirportSearch({ selectedAirports, onSelect, maxSelections }: AirportSearchProps) {
  const [query, setQuery] = useState('');
  const [airports, setAirports] = useState<Airport[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function searchAirports() {
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .rpc('search_airports', { 
            search_term: query.trim(),
            limit_val: 10
          });

        if (error) throw error;
        setAirports(data || []);
      } catch (err) {
        console.error('Error searching airports:', err);
      } finally {
        setIsLoading(false);
      }
    }

    const timer = setTimeout(() => {
      searchAirports();
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <div className="space-y-4">
      <div className="relative">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search airports by code, name, or city..."
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
        />
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
      </div>

      <div className="space-y-2">
        <div className="text-sm text-gray-600">
          Selected: {selectedAirports.length}/{maxSelections}
        </div>
        
        <div className="grid grid-cols-1 gap-2 max-h-60 overflow-y-auto">
          {isLoading ? (
            <div className="text-center py-4 text-gray-500">Searching airports...</div>
          ) : airports.length === 0 ? (
            <div className="text-center py-4 text-gray-500">
              {query ? 'No airports found' : 'Start typing to search airports'}
            </div>
          ) : (
            airports.map((airport) => (
              <label
                key={airport.code}
                className={`
                  relative flex items-center p-3 border rounded-lg cursor-pointer
                  ${selectedAirports.includes(airport.code)
                    ? 'border-primary-500 bg-primary-50'
                    : 'border-gray-200 hover:bg-gray-50'}
                  ${selectedAirports.length >= maxSelections && !selectedAirports.includes(airport.code)
                    ? 'opacity-50 cursor-not-allowed'
                    : ''}
                `}
              >
                <input
                  type="checkbox"
                  checked={selectedAirports.includes(airport.code)}
                  onChange={() => onSelect(airport.code)}
                  disabled={selectedAirports.length >= maxSelections && !selectedAirports.includes(airport.code)}
                  className="h-4 w-4 text-primary-500 rounded border-gray-300"
                />
                <div className="ml-3">
                  <div className="font-medium">
                    {airport.code} - {airport.city}
                  </div>
                  <div className="text-sm text-gray-500">
                    {airport.name}, {airport.country}
                  </div>
                </div>
              </label>
            ))
          )}
        </div>
      </div>
    </div>
  );
}