import React, { useState } from 'react';
import { Plus, ChevronDown, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useServices } from '../../../hooks/services/useServices';
import { useSubscription } from '../../../hooks/subscriptions/useSubscription';
import { ServiceCard } from './components/ServiceCard';
import { ServiceForm } from './components/ServiceForm';
import { EmptyState } from './components/EmptyState';

interface AirportGroup {
  code: string;
  name: string;
  services: Service[];
}

export function ServicesPage() {
  const { services, isLoading, error } = useServices();
  const { plan } = useSubscription();
  const [showForm, setShowForm] = useState(false);
  const [showLimitPopup, setShowLimitPopup] = useState(false);
  const [editingService, setEditingService] = useState<Service | undefined>();
  const [expandedAirports, setExpandedAirports] = useState<string[]>([]);

  // Group services by airport
  const airportGroups = services.reduce((groups, service) => {
    const existingGroup = groups.find(g => g.code === service.airportId);
    if (existingGroup) {
      existingGroup.services.push(service);
    } else {
      groups.push({
        code: service.airportId,
        name: service.airportName || service.airportId,
        services: [service]
      });
    }
    return groups;
  }, [] as AirportGroup[]);

  const handleAddClick = () => {
    if (plan && services.length >= (plan.listing_limit === -1 ? Infinity : plan.listing_limit)) {
      setShowLimitPopup(true);
    } else {
      setShowForm(true);
    }
  };

  const handleEdit = (service: Service) => {
    setEditingService(service);
    setShowForm(true);
  };

  const handleDelete = async (serviceId: string) => {
    if (!confirm('Are you sure you want to delete this service?')) return;

    try {
      const { error: deleteError } = await supabase
        .from('services')
        .delete()
        .eq('id', serviceId);

      if (deleteError) throw deleteError;
    } catch (err) {
      console.error('Error deleting service:', err);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-gray-600">Loading services...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Your Services</h1>
          <p className="mt-1 text-sm text-gray-500">
            {services.length} of {plan?.listing_limit === -1 ? '∞' : plan?.listing_limit} listings used
          </p>
        </div>
        <button 
          onClick={handleAddClick}
          className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600"
        >
          <Plus size={20} />
          Add Service
        </button>
      </div>

      {services.length === 0 ? (
        <EmptyState onAdd={() => setShowForm(true)} />
      ) : (
        <div className="space-y-6">
          {airportGroups.map((group) => (
            <div key={group.code} className="bg-white rounded-lg shadow-sm overflow-hidden">
              <button
                onClick={() => setExpandedAirports(prev => 
                  prev.includes(group.code) 
                    ? prev.filter(code => code !== group.code)
                    : [...prev, group.code]
                )}
                className="w-full flex items-center justify-between p-4 hover:bg-gray-50"
              >
                <div className="flex items-center gap-2">
                  {expandedAirports.includes(group.code) ? (
                    <ChevronDown size={20} className="text-gray-400" />
                  ) : (
                    <ChevronRight size={20} className="text-gray-400" />
                  )}
                  <h2 className="font-medium text-gray-900">{group.name}</h2>
                  <span className="text-sm text-gray-500">
                    ({group.services.length} service{group.services.length !== 1 ? 's' : ''})
                  </span>
                </div>
              </button>

              {expandedAirports.includes(group.code) && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4 bg-gray-50">
                  {group.services.map((service) => (
                    <ServiceCard
                      key={service.id}
                      service={service}
                      onEdit={() => handleEdit(service)}
                      onDelete={() => handleDelete(service.id)}
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {showLimitPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h2 className="text-xl font-semibold mb-4">Service Limit Reached</h2>
            <p className="text-gray-600 mb-6">
              You've reached the maximum number of services allowed on your current plan. 
              Upgrade to add more services.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowLimitPopup(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-900"
              >
                Cancel
              </button>
              <Link
                to="/pricing"
                className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600"
              >
                View Plans
              </Link>
            </div>
          </div>
        </div>
      )}

      {showForm && (
        <ServiceForm
          service={editingService}
          onClose={() => {
            setShowForm(false);
            setEditingService(undefined);
          }}
          onSuccess={() => {
            setShowForm(false);
            setEditingService(undefined);
          }}
        />
      )}
    </div>
  );
}