import React from 'react';
import { MapPin } from 'lucide-react';
import { majorAirports } from '../../../data/airports/majorAirports';
import { formatAirportLabel } from '../../../data/airports/utils';

interface CurrentAirportProps {
  selectedAirport: string;
}

export function CurrentAirport({ selectedAirport }: CurrentAirportProps) {
  const airport = majorAirports.find(a => a.id === selectedAirport);
  
  if (!airport) return null;

  return (
    <div className="bg-blue-50 border-b border-blue-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="flex items-center gap-2 text-blue-700">
          <MapPin size={16} />
          <span className="text-sm font-medium">{formatAirportLabel(airport)}</span>
        </div>
      </div>
    </div>
  );
}