import type { PricingPlan } from '../types';

export const plans: PricingPlan[] = [
  {
    id: 'free',
    name: 'Free',
    description: 'Perfect for getting started',
    price: 0,
    features: [
      '1 service listing',
      '1 airport location',
      'Basic analytics',
      'Email support'
    ]
  },
  {
    id: 'basic',
    name: 'Basic',
    description: 'Ideal for small businesses',
    price: 29.99,
    features: [
      '2 service listings',
      '1 airport location per listing',
      'Basic analytics',
      'Email support',
      'Featured listings'
    ]
  },
  {
    id: 'pro',
    name: 'Professional',
    description: 'Perfect for growing businesses',
    price: 99.99,
    features: [
      '5 service listings',
      '3 airport locations per listing',
      'Advanced analytics',
      'Priority support',
      'Featured listings',
      'Custom branding'
    ],
    popular: true
  },
  {
    id: 'enterprise',
    name: 'Enterprise',
    description: 'For large-scale operations',
    price: 299.99,
    features: [
      'Unlimited service listings',
      'Unlimited airports',
      'Enterprise analytics',
      '24/7 phone support',
      'Featured listings',
      'Custom branding',
      'API access'
    ]
  }
];