import { useEffect, useState } from 'react';
import { supabase } from '../../lib/supabase/client';
import { useAuthContext } from '../../contexts/AuthContext';
import type { Service } from '../../types';

export function useServices(airportCode?: string) {
  const { user } = useAuthContext();
  const [services, setServices] = useState<Service[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchServices() {
      try {
        let query = supabase
          .from('services')
          .select(`
            *,
            service_airports!inner(
              airport_code,
              airport:airports!inner(
                code,
                name,
                country
              )
            ),
            provider:profiles!inner(
              business_name,
              is_verified
            ),
            images:service_images(
              url,
              position
            )
          `);

        // If viewing from dashboard, only show user's services
        if (window.location.pathname.startsWith('/dashboard')) {
          query = query.eq('profile_id', user?.id);
        }
        
        // If viewing by airport, filter by airport code
        if (airportCode) {
          query = query.eq('service_airports.airport_code', airportCode.toUpperCase());
        }

        const { data, error: err } = await query;
        if (err) throw err;

        // Transform the data to match our Service type
        const formattedServices = (data || []).map(service => ({
          id: service.id,
          title: service.title,
          description: service.description,
          price: Number(service.price),
          airportId: service.service_airports[0].airport_code,
          airportName: service.service_airports[0].airport.name,
          category: service.category,
          contactEmail: service.contact_email,
          contactPhone: service.contact_phone,
          contactAddress: service.contact_address,
          contactWebsite: service.contact_website,
          contactForPrice: service.contact_for_price,
          images: service.images?.map(img => img.url) || [],
          provider: {
            name: service.provider.business_name,
            rating: 4.5, // Placeholder
            reviews: 10, // Placeholder
            image: '', // Using initials instead
            isVerified: service.provider.is_verified
          }
        }));

        setServices(formattedServices);
      } catch (err) {
        console.error('Error fetching services:', err);
        setError('Failed to load services');
      } finally {
        setIsLoading(false);
      }
    }

    fetchServices();
  }, [airportCode, user]);

  return { services, isLoading, error };
}