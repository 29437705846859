import React from 'react';
import { MetaTags } from '../../components/seo/MetaTags';
import { StructuredData } from '../../components/seo/StructuredData';
import { PricingCard } from './components/PricingCard';
import { plans } from './data/plans';

export function PricingPage() {
  const pageTitle = 'Pricing Plans - Aviation Service Provider Solutions';
  const pageDescription = 'Choose the perfect plan for your aviation service business. Flexible pricing options for ground handling, maintenance, catering, and more.';
  const canonicalUrl = `${window.location.origin}/pricing`;

  const organizationData = {
    logo: 'https://airportal.com/logo.png',
    socialLinks: [
      'https://twitter.com/airportal',
      'https://linkedin.com/company/airportal'
    ]
  };

  // Reorder plans to show free at the bottom
  const mainPlans = plans.filter(plan => plan.id !== 'free');
  const freePlan = plans.find(plan => plan.id === 'free');

  return (
    <>
      <MetaTags
        title={pageTitle}
        description={pageDescription}
        canonicalUrl={canonicalUrl}
        keywords={[
          'aviation pricing',
          'airport service plans',
          'ground handling pricing',
          'aviation service provider'
        ]}
      />

      <StructuredData type="Organization" data={organizationData} />

      <main className="min-h-screen bg-gradient-to-br from-primary-50 via-blue-50 to-primary-50 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              Simple, Transparent Pricing
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Choose the plan that best fits your business needs. Scale your services with confidence.
            </p>
          </div>

          {/* Main pricing cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            {mainPlans.map((plan) => (
              <PricingCard key={plan.id} plan={plan} />
            ))}
          </div>

          {/* Free plan */}
          {freePlan && (
            <div className="max-w-lg mx-auto">
              <div className="text-center mb-6">
                <h2 className="text-2xl font-semibold text-gray-900">Start for Free</h2>
                <p className="text-gray-600">Get started with our basic features at no cost</p>
              </div>
              <PricingCard plan={freePlan} />
            </div>
          )}

          {/* Additional info */}
          <div className="mt-16 text-center">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Need something different?
            </h2>
            <p className="text-gray-600 mb-6">
              Contact us for custom enterprise solutions tailored to your specific needs.
            </p>
            <div className="space-y-4">
              <a
                href="mailto:contact@airportals.net"
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-primary-700 bg-primary-50 hover:bg-primary-100 transition-colors"
              >
                Contact Sales
              </a>
              <div className="text-sm text-gray-500">
                Or email us directly at: <a href="mailto:contact@airportals.net" className="text-primary-600 hover:text-primary-700">contact@airportals.net</a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}