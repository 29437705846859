import React from 'react';
import { ListPlus, MapPin, CreditCard } from 'lucide-react';
import { useServices } from '../../../hooks/services/useServices';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useSubscription } from '../../../hooks/subscriptions/useSubscription';
import { Link } from 'react-router-dom';

export function DashboardOverviewPage() {
  const { user } = useAuthContext();
  const { services, isLoading: servicesLoading } = useServices();
  const { subscription, plan, isLoading: subscriptionLoading } = useSubscription();

  if (servicesLoading || subscriptionLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-gray-600">Loading...</div>
      </div>
    );
  }

  const uniqueAirports = new Set(services.map(s => s.airportId));

  const stats = [
    {
      name: 'Active Services',
      value: services.length,
      icon: ListPlus,
      description: `${plan?.listing_limit === -1 ? '∞' : plan?.listing_limit} total allowed`
    },
    {
      name: 'Airports Served',
      value: uniqueAirports.size,
      icon: MapPin,
      description: `${plan?.airport_limit === -1 ? '∞' : plan?.airport_limit} per service allowed`
    }
  ];

  return (
    <div>
      <div className="mb-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Welcome back, {user?.user_metadata.business_name}
        </h1>
        <p className="mt-1 text-sm text-gray-500">
          Here's what's happening with your services
        </p>
      </div>

      {/* Subscription Status */}
      <div className="mb-8 bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-lg font-medium flex items-center gap-2">
              <CreditCard className="text-gray-400" size={20} />
              Current Plan: {plan?.name || 'Free'}
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              {services.length} of {plan?.listing_limit === -1 ? '∞' : plan?.listing_limit} listings used
            </p>
          </div>
          {plan?.id === 'free' && (
            <Link
              to="/pricing"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600"
            >
              Upgrade for more listings
            </Link>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
        {stats.map((stat) => (
          <div key={stat.name} className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <stat.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      {stat.name}
                    </dt>
                    <dd className="flex items-baseline">
                      <div className="text-2xl font-semibold text-gray-900">
                        {stat.value}
                      </div>
                    </dd>
                    <dd className="text-sm text-gray-500">
                      {stat.description}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Recent Activity</h2>
        <div className="bg-white shadow rounded-lg">
          {services.length > 0 ? (
            <ul className="divide-y divide-gray-200">
              {services.slice(0, 5).map((service) => (
                <li key={`${service.id}-${service.airportId}`} className="p-4">
                  <div className="flex items-center space-x-4">
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {service.title}
                      </p>
                      <p className="text-sm text-gray-500">
                        {service.airportId} • ${service.price}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="p-4 text-center text-gray-500">
              No services yet. Add your first service to get started.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}