import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { Plane } from 'lucide-react';
import { supabase } from '../../lib/supabase/client';
import { useAuthContext } from '../../contexts/AuthContext';

export function SignInPage() {
  const { user, loading } = useAuthContext();
  const navigate = useNavigate();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-primary-50 via-blue-50 to-primary-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="text-center">
            <div className="animate-pulse">
              <div className="h-12 w-12 mx-auto bg-gray-200 rounded-lg mb-4"></div>
              <div className="h-8 bg-gray-200 rounded max-w-[200px] mx-auto mb-2"></div>
              <div className="h-4 bg-gray-200 rounded max-w-[300px] mx-auto"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (user) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary-50 via-blue-50 to-primary-50">
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
          <div className="flex justify-center mb-6">
            <div className="bg-primary-500 p-3 rounded-2xl shadow-lg">
              <Plane className="h-8 w-8 text-white" />
            </div>
          </div>
          <h2 className="text-center text-3xl font-bold text-gray-900 mb-2">
            Welcome back
          </h2>
          <Link 
            to="/signup"
            className="inline-block px-6 py-2 bg-primary-500 text-white font-medium rounded-lg hover:bg-primary-600 transition-colors mb-8"
          >
            Create an account
          </Link>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white/80 backdrop-blur-sm py-8 px-4 shadow-xl sm:rounded-xl sm:px-10 border border-white/20">
            <Auth
              supabaseClient={supabase}
              appearance={{ 
                theme: ThemeSupa,
                variables: {
                  default: {
                    colors: {
                      brand: '#008080',
                      brandAccent: '#006666',
                      inputBackground: 'white',
                      inputBorder: '#E5E7EB',
                      inputBorderHover: '#008080',
                      inputBorderFocus: '#008080',
                    }
                  },
                },
                className: {
                  container: 'w-full',
                  button: 'w-full bg-primary-500 hover:bg-primary-600 text-white font-medium py-3 px-4 rounded-lg transition-all transform hover:scale-[1.02] hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 disabled:hover:shadow-none',
                  input: 'w-full rounded-lg border border-gray-300 px-4 py-3 bg-white/90 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent transition-shadow',
                  label: 'block text-sm font-medium text-gray-700 mb-1',
                  message: 'mt-2 text-sm text-red-600 bg-red-50 px-3 py-2 rounded-lg',
                  anchor: 'hidden', // Hide all auth component anchors
                }
              }}
              providers={[]}
              view={showForgotPassword ? 'forgotten_password' : 'sign_in'}
              redirectTo={`${window.location.origin}/dashboard`}
              localization={{
                variables: {
                  sign_in: {
                    email_label: 'Email address',
                    password_label: 'Password',
                    button_label: 'Sign in to your account',
                    loading_button_label: 'Signing in...',
                    link_text: '',
                  },
                  forgotten_password: {
                    button_label: 'Send reset instructions',
                    confirmation_text: 'Check your email for the password reset link',
                    email_label: 'Email address',
                    password_label: 'New password',
                    link_text: '',
                  }
                }
              }}
            />

            {!showForgotPassword && (
              <button
                onClick={() => setShowForgotPassword(true)}
                className="mt-4 w-full text-center text-sm text-primary-600 hover:text-primary-700"
              >
                Forgot your password?
              </button>
            )}

            {showForgotPassword && (
              <button
                onClick={() => setShowForgotPassword(false)}
                className="mt-4 w-full text-center text-sm text-primary-600 hover:text-primary-700"
              >
                Back to sign in
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}