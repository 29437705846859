import type { Airport } from './types';

export function formatAirportLabel(airport: Airport): string {
  const cityCountry = [
    airport.city,
    airport.country
  ].filter(Boolean).join(', ');
  
  return `${airport.iata}${cityCountry ? ` - ${cityCountry}` : ''}`;
}

export function searchAirports(airports: Airport[], query: string): Airport[] {
  if (!query.trim()) return airports.slice(0, 10); // Show first 10 airports when no query

  const searchTerm = query.toLowerCase();
  return airports.filter(airport => {
    // Ensure required fields exist
    if (!airport.iata) return false;

    // Search through all available fields
    const searchFields = [
      airport.iata,
      airport.city,
      airport.name,
      airport.country,
      airport.icao
    ].filter(Boolean).map(field => field.toLowerCase());

    return searchFields.some(field => field.includes(searchTerm));
  });
}