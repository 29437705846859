import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
  LayoutDashboard, 
  ListPlus, 
  Settings, 
  BarChart2,
  CalendarClock
} from 'lucide-react';

const navigation = [
  { name: 'Overview', href: '/dashboard', icon: LayoutDashboard },
  { name: 'Services', href: '/dashboard/services', icon: ListPlus },
  { name: 'Analytics', href: '/dashboard/analytics', icon: BarChart2 },
  { name: 'Bookings', href: '/dashboard/bookings', icon: CalendarClock },
  { name: 'Settings', href: '/dashboard/settings', icon: Settings },
];

export function DashboardNav() {
  return (
    <nav className="space-y-1 px-2">
      {navigation.map((item) => (
        <NavLink
          key={item.name}
          to={item.href}
          className={({ isActive }) =>
            `group flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors ${
              isActive
                ? 'bg-primary-50 text-primary-600'
                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
            }`
          }
        >
          <item.icon
            className={`mr-3 h-5 w-5 flex-shrink-0 transition-colors ${
              location.pathname === item.href
                ? 'text-primary-500'
                : 'text-gray-400 group-hover:text-gray-500'
            }`}
          />
          {item.name}
        </NavLink>
      ))}
    </nav>
  );
}