import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Logo } from './Logo';
import { SearchBar } from './SearchBar';
import { Navigation } from './Navigation';
import { CurrentAirport } from './CurrentAirport';

interface HeaderProps {
  selectedAirport: string;
  onAirportSelect: (airportId: string) => void;
}

export function Header({ selectedAirport, onAirportSelect }: HeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header>
      <div className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Mobile Header */}
          <div className="lg:hidden">
            <div className="flex items-center justify-between h-16">
              <Logo />
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2 rounded-md text-gray-600 hover:text-gray-900"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
            {/* Mobile Search - Always visible */}
            <div className="pb-4">
              <SearchBar
                selectedAirport={selectedAirport}
                onAirportSelect={onAirportSelect}
              />
            </div>
          </div>

          {/* Desktop Header */}
          <div className="hidden lg:flex items-center justify-between py-4">
            <Logo />
            <div className="flex-1 flex justify-center mx-8">
              <SearchBar
                selectedAirport={selectedAirport}
                onAirportSelect={onAirportSelect}
              />
            </div>
            <Navigation />
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="lg:hidden border-t border-gray-200">
            <div className="px-4 py-3">
              <Navigation />
            </div>
          </div>
        )}
      </div>
      <CurrentAirport selectedAirport={selectedAirport} />
    </header>
  );
}