import React from 'react';
import { Star, Quote } from 'lucide-react';

const testimonials = [
  {
    id: 1,
    content: "AirPortals has been a game-changer for our business. Since listing our services, we've connected with clients we never would have reached otherwise. The process was simple, and the free listing option gave us the perfect starting point!",
    author: "Rachel T",
    role: "FBO Manager"
  },
  {
    id: 2,
    content: "We love how easy it was to set up our listing and showcase the airports we serve. The platform's visibility has significantly boosted our bookings, and the support team has been fantastic!",
    author: "Caleb S",
    role: "CEO"
  },
  {
    id: 3,
    content: "As a small aviation service provider, having a presence on AirPortals has been invaluable. The detailed listing options and the ability to add photos really helped us stand out from competitors",
    author: "Daniel V",
    role: "Charter Broker"
  }
];

export function Testimonials() {
  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-2">
            Trusted by Aviation Professionals
          </h2>
          <p className="text-gray-600">
            Join thousands of service providers growing their business with AirPortals
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="bg-gray-50 rounded-2xl p-8 relative"
            >
              <Quote className="w-10 h-10 text-primary-200 absolute top-6 right-6" />
              
              <div className="flex items-center gap-1 mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    className="w-5 h-5 fill-yellow-400 text-yellow-400"
                  />
                ))}
              </div>
              
              <blockquote className="text-gray-600 mb-6">
                "{testimonial.content}"
              </blockquote>
              
              <div>
                <div className="font-medium text-gray-900">
                  {testimonial.author}
                </div>
                <div className="text-sm text-gray-500">
                  {testimonial.role}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}