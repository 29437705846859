import { Car, UtensilsCrossed, Building2, Wrench, Store, Plane, Warehouse, MoreHorizontal } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

export interface Category {
  id: CategoryId;
  name: string;
  icon: LucideIcon;
  description: string;
}

export type CategoryId = 
  | 'ground-transport'
  | 'catering'
  | 'stays'
  | 'maintenance'
  | 'local'
  | 'charter'
  | 'fbos'
  | 'other';

export const categories: Category[] = [
  {
    id: 'ground-transport',
    name: 'Ground Transportation',
    icon: Car,
    description: 'Airport transfers, car services, and rental vehicles'
  },
  {
    id: 'catering',
    name: 'Restaurants & Catering',
    icon: UtensilsCrossed,
    description: 'In-flight catering and local dining options'
  },
  {
    id: 'stays',
    name: 'Stays & Rentals',
    icon: Building2,
    description: 'Hotels, temporary housing, and facility rentals'
  },
  {
    id: 'maintenance',
    name: 'Maintenance & Detailing',
    icon: Wrench,
    description: 'Aircraft maintenance, cleaning, and detailing services'
  },
  {
    id: 'local',
    name: 'Local Businesses',
    icon: Store,
    description: 'Airport-adjacent services and local vendors'
  },
  {
    id: 'charter',
    name: 'Charter',
    icon: Plane,
    description: 'Aircraft charter services and private flights'
  },
  {
    id: 'fbos',
    name: 'FBOs',
    icon: Warehouse,
    description: 'Fixed-base operator services and facilities'
  },
  {
    id: 'other',
    name: 'Other',
    icon: MoreHorizontal,
    description: 'Additional aviation-related services'
  }
];