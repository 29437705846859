import React from 'react';
import { BusinessSettings } from './components/BusinessSettings';
import { SecuritySettings } from './components/SecuritySettings';
import { NotificationSettings } from './components/NotificationSettings';

export function SettingsPage() {
  return (
    <div>
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">Settings</h1>
      
      <div className="space-y-8">
        <BusinessSettings />
        <SecuritySettings />
        <NotificationSettings />
      </div>
    </div>
  );
}