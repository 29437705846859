import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string;
}

const faqs: FAQItem[] = [
  {
    question: "How do I list my aviation service?",
    answer: "Sign up for an account and start with a free listing! Create your service listing, add details, upload photos, and specify the airports you serve. If you need more features or visibility, you can choose a subscription plan that suits your business needs."
  },
  {
    question: "What types of services can I list?",
    answer: "We support a wide range of aviation services including ground transportation, catering, maintenance, FBO services, aircraft cleaning, and more. Any service that supports aviation operations can be listed."
  },
  {
    question: "How much does it cost to list a service?",
    answer: "We offer multiple pricing tiers starting with a free plan. Premium plans start at $29.99/month and include additional features like multiple service listings and priority placement."
  },
  {
    question: "Can I list services at multiple airports?",
    answer: "Yes! Depending on your subscription plan, you can list your services at multiple airports. Our Professional and Enterprise plans support multiple airport listings per service."
  },
  {
    question: "Do I need technical skills to create a listing?",
    answer: "Not at all! Our platform is user-friendly, allowing you to easily create and manage your listing with step-by-step instructions."
  }
];

export function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-2">
          Frequently Asked Questions
        </h2>
        <p className="text-gray-600 text-center mb-12">
          Everything you need to know about listing your aviation services
        </p>

        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden"
            >
              <button
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                className="w-full px-6 py-4 text-left flex items-center justify-between hover:bg-gray-50"
              >
                <span className="text-lg font-medium text-gray-900">
                  {faq.question}
                </span>
                <ChevronDown
                  className={`w-5 h-5 text-gray-500 transition-transform ${
                    openIndex === index ? 'transform rotate-180' : ''
                  }`}
                />
              </button>
              
              <div
                className={`
                  px-6 overflow-hidden transition-all duration-200 ease-in-out
                  ${openIndex === index ? 'py-4' : 'max-h-0'}
                `}
              >
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}