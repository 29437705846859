import React from 'react';
import { MetaTags } from '../../components/seo/MetaTags';

export function TermsPage() {
  return (
    <>
      <MetaTags
        title="Terms of Service - AirPortal"
        description="Read about the terms and conditions governing the use of AirPortal's services."
      />

      <main className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
          
          <div className="bg-white rounded-xl shadow-sm p-8 space-y-8">
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Agreement to Terms</h2>
              <p className="text-gray-600">
                By accessing or using AirPortal's website and services, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not access the service.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">User Accounts</h2>
              <div className="space-y-4">
                <p className="text-gray-600">
                  When you create an account with us, you must provide accurate, complete, and current information. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account.
                </p>
                <p className="text-gray-600">
                  You are responsible for:
                </p>
                <ul className="list-disc list-inside text-gray-600 ml-4 space-y-2">
                  <li>Maintaining the confidentiality of your account and password</li>
                  <li>Restricting access to your computer and/or account</li>
                  <li>All activities that occur under your account and password</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Service Provider Obligations</h2>
              <div className="space-y-4">
                <p className="text-gray-600">
                  As a service provider on our platform, you agree to:
                </p>
                <ul className="list-disc list-inside text-gray-600 ml-4 space-y-2">
                  <li>Provide accurate and complete information about your services</li>
                  <li>Maintain all necessary licenses and permits</li>
                  <li>Comply with all applicable laws and regulations</li>
                  <li>Maintain appropriate insurance coverage</li>
                  <li>Respond to customer inquiries in a timely manner</li>
                  <li>Deliver services as described in your listings</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Intellectual Property</h2>
              <p className="text-gray-600">
                The Service and its original content, features, and functionality are owned by AirPortal and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Payment Terms</h2>
              <div className="space-y-4">
                <p className="text-gray-600">
                  Subscription fees are billed in advance on a monthly basis. You agree to pay all fees associated with your account. Fees are non-refundable except as required by law or as explicitly stated in our refund policy.
                </p>
                <p className="text-gray-600">
                  We reserve the right to modify our pricing with 30 days notice. Continued use of the service after a price change constitutes acceptance of the new pricing.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Limitation of Liability</h2>
              <p className="text-gray-600">
                In no event shall AirPortal, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Termination</h2>
              <p className="text-gray-600">
                We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Changes to Terms</h2>
              <p className="text-gray-600">
                We reserve the right to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Contact Information</h2>
              <p className="text-gray-600">
                If you have any questions about these Terms, please contact us at legal@airportal.com.
              </p>
            </section>

            <div className="text-sm text-gray-500 pt-8 border-t">
              Last updated: January 7, 2025
            </div>
          </div>
        </div>
      </main>
    </>
  );
}