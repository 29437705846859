import React from 'react';
import { Helmet } from 'react-helmet-async';

interface ServiceSchema {
  name: string;
  description: string;
  provider: {
    name: string;
    image: string;
  };
  price: number;
  image?: string;
  location: {
    name: string;
    address: string;
  };
}

interface StructuredDataProps {
  type: 'Service' | 'Organization' | 'BreadcrumbList';
  data: ServiceSchema | any;
}

export function StructuredData({ type, data }: StructuredDataProps) {
  const getSchemaMarkup = () => {
    switch (type) {
      case 'Service':
        return {
          '@context': 'https://schema.org',
          '@type': 'Service',
          name: data.name,
          description: data.description,
          provider: {
            '@type': 'Organization',
            name: data.provider.name,
            image: data.provider.image
          },
          offers: {
            '@type': 'Offer',
            price: data.price,
            priceCurrency: 'USD'
          },
          ...(data.image && { image: data.image }),
          areaServed: {
            '@type': 'Place',
            name: data.location.name,
            address: data.location.address
          }
        };
      
      case 'Organization':
        return {
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'AirPortal',
          description: 'Aviation Services Marketplace',
          url: 'https://airportal.com',
          logo: data.logo,
          sameAs: data.socialLinks
        };

      case 'BreadcrumbList':
        return {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: data.items.map((item: any, index: number) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: item.name,
            item: item.url
          }))
        };

      default:
        return {};
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(getSchemaMarkup())}
      </script>
    </Helmet>
  );
}