import React from 'react';
import { useSubscribeToStripe } from './useSubscribeToStripe';

interface SubscribeButtonProps {
  planId: 'basic' | 'pro' | 'enterprise';
  className?: string;
}

export function SubscribeButton({ planId, className = '' }: SubscribeButtonProps) {
  const { handleSubscribe, isLoading, error } = useSubscribeToStripe(planId);

  return (
    <div className="flex flex-col items-center">
      <button
        onClick={handleSubscribe}
        disabled={isLoading}
        className={`px-6 py-3 rounded-lg font-semibold transition-colors disabled:opacity-50 ${className}`}
      >
        {isLoading ? 'Loading...' : `Subscribe to ${planId.charAt(0).toUpperCase() + planId.slice(1)}`}
      </button>
      {error && <div className="mt-2 text-sm text-red-600">{error}</div>}
    </div>
  );
}