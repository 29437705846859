import { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase/client';

export function useServiceImages(serviceId?: string) {
  const [images, setImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function loadImages() {
      if (!serviceId) {
        setIsLoading(false);
        return;
      }

      try {
        const { data, error: fetchError } = await supabase
          .from('service_images')
          .select('url')
          .eq('service_id', serviceId)
          .order('position');

        if (fetchError) throw fetchError;
        setImages(data.map(img => img.url));
      } catch (err) {
        console.error('Error loading service images:', err);
        setError('Failed to load images');
      } finally {
        setIsLoading(false);
      }
    }

    loadImages();
  }, [serviceId]);

  const updateImages = async (serviceId: string, urls: string[]) => {
    try {
      // Delete existing images
      await supabase
        .from('service_images')
        .delete()
        .eq('service_id', serviceId);

      // Insert new images
      if (urls.length > 0) {
        const { error: insertError } = await supabase
          .from('service_images')
          .insert(
            urls.map((url, index) => ({
              service_id: serviceId,
              url,
              position: index
            }))
          );

        if (insertError) throw insertError;
      }

      setImages(urls);
    } catch (err) {
      console.error('Error updating service images:', err);
      throw err;
    }
  };

  return {
    images,
    isLoading,
    error,
    updateImages
  };
}