import React from 'react';
import { Link } from 'react-router-dom';
import { Plane, ArrowLeft } from 'lucide-react';
import { MetaTags } from '../components/seo/MetaTags';

export function NotFoundPage() {
  return (
    <>
      <MetaTags
        title="Page Not Found - AirPortal"
        description="The page you're looking for could not be found."
        noindex={true}
      />

      <main className="min-h-screen bg-gradient-to-br from-primary-50 via-blue-50 to-primary-50 flex items-center justify-center p-4">
        <div className="text-center">
          <div className="inline-flex p-4 bg-primary-500 rounded-full mb-8">
            <Plane className="h-12 w-12 text-white" />
          </div>
          
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Page Not Found
          </h1>
          
          <p className="text-lg text-gray-600 mb-8 max-w-md mx-auto">
            The page you're looking for has taken off to an unknown destination.
          </p>

          <Link 
            to="/"
            className="inline-flex items-center gap-2 px-6 py-3 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
          >
            <ArrowLeft className="h-5 w-5" />
            Return Home
          </Link>
        </div>
      </main>
    </>
  );
}