import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import { majorAirports } from '../data/airports/majorAirports';
import { searchAirports, formatAirportLabel } from '../data/airports/utils';
import { getAirportUrl } from '../utils/navigation';
import type { Airport } from '../data/airports/types';

interface AirportSearchInputProps {
  selectedAirport?: string;
  onSelect: (airportId: string) => void;
}

export function AirportSearchInput({ selectedAirport, onSelect }: AirportSearchInputProps) {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [airports, setAirports] = useState(majorAirports);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (query.length >= 2 && !isLoading) {
      setIsLoading(true);
      import('../data/airports/fullAirportList').then(({ airports }) => {
        setAirports(airports);
        setIsLoading(false);
      });
    }
  }, [query]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectedAirportData = airports.find(a => a.id === selectedAirport);

  const handleAirportSelect = (airport: Airport) => {
    setIsOpen(false);
    setQuery('');
    onSelect(airport.id);
    navigate(getAirportUrl(airport));
  };

  const filteredAirports = searchAirports(airports, query);

  return (
    <div className="relative flex-1 min-w-[240px]" ref={dropdownRef}>
      <div className="relative">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={() => setIsOpen(true)}
          placeholder={selectedAirportData ? formatAirportLabel(selectedAirportData) : "Search airports..."}
          className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
        />
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
      </div>

      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-auto">
          {isLoading ? (
            <div className="p-4 text-center text-gray-500">Loading airports...</div>
          ) : filteredAirports.length === 0 ? (
            <div className="p-4 text-center text-gray-500">No airports found</div>
          ) : (
            filteredAirports.map((airport) => (
              <button
                key={airport.id}
                onClick={() => handleAirportSelect(airport)}
                className="w-full px-4 py-2 text-left hover:bg-gray-50 focus:bg-gray-50"
              >
                <div className="font-medium">{airport.iata} - {airport.city}</div>
                <div className="text-sm text-gray-500">{airport.name}</div>
              </button>
            ))
          )}
        </div>
      )}
    </div>
  );
}