import React from 'react';
import { MapPin } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import type { Service } from '../../types';

interface ServiceCardProps {
  service: Service;
}

export function ServiceCard({ service }: ServiceCardProps) {
  const location = useLocation();
  const mainImage = service.images?.[0] || 'https://images.unsplash.com/photo-1436491865332-7a61a109cc05';
  const truncatedDescription = service.description.length > 180 
    ? `${service.description.substring(0, 180)}...` 
    : service.description;

  return (
    <article className="bg-white rounded-lg shadow-md overflow-hidden transition-transform hover:scale-[1.02]">
      <Link 
        to={`/services/${service.id}`} 
        state={{ from: location.pathname }}
        className="block"
      >
        <header>
          <div className="relative h-48">
            <img
              src={mainImage}
              alt={`${service.title} at ${service.airportName || service.airportId}`}
              className="w-full h-full object-cover"
              loading="lazy"
            />
            <div 
              className="absolute top-4 right-4 bg-white px-3 py-1 rounded-full text-sm font-semibold"
              aria-label={service.contactForPrice ? 'Contact for pricing' : `Price: $${service.price}`}
            >
              {service.contactForPrice ? 'Contact for price' : `$${service.price}`}
            </div>
          </div>
        </header>

        <div className="p-5">
          <div className="flex items-center gap-2 text-sm text-gray-600 mb-2">
            <MapPin size={16} aria-hidden="true" />
            <span>{service.airportName || service.airportId}</span>
          </div>

          <h2 className="text-xl font-semibold mb-2">
            <span className="text-gray-900 hover:text-primary-600 transition-colors">
              {service.title}
            </span>
          </h2>

          <p className="text-gray-600 text-sm">
            {truncatedDescription}
          </p>
        </div>
      </Link>
    </article>
  );
}