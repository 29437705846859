import React from 'react';
import { Link } from 'react-router-dom';
import { Plane, Linkedin, Instagram } from 'lucide-react';

const navigation = {
  services: [
    { name: 'Ground Transportation', href: '/airport/jfk' },
    { name: 'Aircraft Maintenance', href: '/airport/jfk' },
    { name: 'Catering Services', href: '/airport/jfk' },
    { name: 'FBO Services', href: '/airport/jfk' },
  ],
  company: [
    { name: 'Pricing', href: '/pricing' },
    { name: 'List Your Service', href: '/signup' },
    { name: 'Sign In', href: '/signin' },
  ],
  legal: [
    { name: 'Privacy Policy', href: '/privacy' },
    { name: 'Terms of Service', href: '/terms' },
    { name: 'Cookie Policy', href: '/cookies' },
  ],
  social: [
    { name: 'LinkedIn', icon: Linkedin, href: '#' },
    { name: 'Instagram', icon: Instagram, href: '#' },
  ],
};

export function Footer() {
  return (
    <footer className="bg-gray-900 mt-auto" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <Link to="/" className="flex items-center gap-2">
              <div className="bg-primary-500 p-2 rounded-lg">
                <Plane className="h-6 w-6 text-white" />
              </div>
              <span className="text-2xl font-bold text-white">AirPortals</span>
            </Link>
            <p className="text-gray-400 text-sm">
              Connecting aviation service providers with clients worldwide. Streamline your operations and grow your business with our comprehensive platform.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-300"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" />
                </a>
              ))}
            </div>
          </div>
          
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-200 tracking-wider uppercase">
                  Services
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.services.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-base text-gray-400 hover:text-gray-300">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-200 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-base text-gray-400 hover:text-gray-300">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-200 tracking-wider uppercase">
                Legal
              </h3>
              <ul className="mt-4 space-y-4">
                {navigation.legal.map((item) => (
                  <li key={item.name}>
                    <Link to={item.href} className="text-base text-gray-400 hover:text-gray-300">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        
        <div className="mt-12 border-t border-gray-800 pt-8">
          <div className="text-center space-y-4">
            <p className="text-base text-gray-400">
              &copy; {new Date().getFullYear()} AirPortals. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}