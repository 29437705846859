import React, { useState } from 'react';
import { CalendarClock, Users, ClipboardList, Check } from 'lucide-react';
import { supabase } from '../../../lib/supabase/client';

export function BookingsPage() {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const { error: insertError } = await supabase
        .from('waitlist_entries')
        .insert({
          email,
          feature: 'bookings'
        });

      if (insertError) throw insertError;
      
      setIsSuccess(true);
      setEmail('');
    } catch (err) {
      console.error('Error joining waitlist:', err);
      setError(err instanceof Error ? err.message : 'Failed to join waitlist');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="text-center mb-12">
        <div className="flex justify-center mb-6">
          <div className="p-4 bg-primary-50 rounded-full">
            <CalendarClock className="w-12 h-12 text-primary-500" />
          </div>
        </div>
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Booking Management Coming Soon
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          We're working on a powerful booking management system to help you streamline your operations.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center gap-4 mb-4">
            <div className="p-2 bg-primary-50 rounded-lg">
              <ClipboardList className="w-6 h-6 text-primary-500" />
            </div>
            <h2 className="text-lg font-semibold text-gray-900">
              Manage Bookings
            </h2>
          </div>
          <p className="text-gray-600">
            Accept, manage, and track service bookings all in one place. Streamline your scheduling and operations.
          </p>
        </div>

        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center gap-4 mb-4">
            <div className="p-2 bg-primary-50 rounded-lg">
              <Users className="w-6 h-6 text-primary-500" />
            </div>
            <h2 className="text-lg font-semibold text-gray-900">
              Customer Management
            </h2>
          </div>
          <p className="text-gray-600">
            Keep track of customer preferences, history, and special requirements for better service delivery.
          </p>
        </div>
      </div>

      <div className="bg-primary-50 rounded-xl p-8">
        <div className="text-center max-w-md mx-auto">
          <h3 className="text-lg font-semibold text-primary-900 mb-2">
            Want early access?
          </h3>
          <p className="text-primary-700 mb-6">
            Join our waitlist to be among the first to try our booking management system.
          </p>

          {isSuccess ? (
            <div className="flex items-center justify-center gap-2 text-primary-600">
              <Check className="w-5 h-5" />
              <span>You're on the list! We'll notify you when it's ready.</span>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              {error && (
                <div className="text-sm text-red-600 bg-red-50 p-2 rounded-lg">
                  {error}
                </div>
              )}
              
              <div className="flex gap-2">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="flex-1 px-4 py-2 rounded-lg border border-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500"
                  required
                />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-6 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors disabled:opacity-50"
                >
                  {isSubmitting ? 'Joining...' : 'Join Waitlist'}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}