import React, { useState } from 'react';
import { parsePhoneNumberFromString, CountryCode, getCountries } from 'libphonenumber-js';
import { ChevronDown, Phone } from 'lucide-react';

interface PhoneInputProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  error?: string;
}

const countries = getCountries();

export function PhoneInput({ value, onChange, className = '', error }: PhoneInputProps) {
  const [isOpen, setIsOpen] = useState(false);
  const parsedNumber = parsePhoneNumberFromString(value);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(
    (parsedNumber?.country as CountryCode) || 'US'
  );

  const handleCountrySelect = (country: CountryCode) => {
    setSelectedCountry(country);
    setIsOpen(false);
    
    // Update phone number with new country code
    const phoneNumber = parsePhoneNumberFromString(value, country);
    if (phoneNumber) {
      onChange(phoneNumber.formatInternational());
    } else {
      const example = parsePhoneNumberFromString(`+${getCountryCallingCode(country)}`, country);
      onChange(example?.formatInternational() || '');
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/[^\d\s+()-]/g, '');
    const phoneNumber = parsePhoneNumberFromString(input, selectedCountry);
    
    if (phoneNumber) {
      onChange(phoneNumber.formatInternational());
    } else {
      onChange(input);
    }
  };

  return (
    <div className="relative">
      <div className="relative flex">
        <div className="relative">
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center gap-1 px-3 py-2 border border-r-0 border-gray-300 rounded-l-lg bg-gray-50 hover:bg-gray-100"
          >
            <span className="text-sm">+{getCountryCallingCode(selectedCountry)}</span>
            <ChevronDown size={16} className="text-gray-500" />
          </button>

          {isOpen && (
            <div className="absolute z-50 top-full left-0 mt-1 w-64 max-h-60 overflow-auto bg-white border border-gray-200 rounded-lg shadow-lg">
              {countries.map((country) => (
                <button
                  key={country}
                  type="button"
                  onClick={() => handleCountrySelect(country)}
                  className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center justify-between"
                >
                  <span>{getCountryName(country)}</span>
                  <span className="text-gray-500">+{getCountryCallingCode(country)}</span>
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="flex-1 relative">
          <input
            type="tel"
            value={value}
            onChange={handlePhoneChange}
            className={`pl-10 rounded-l-none ${className}`}
            placeholder="Phone number"
          />
          <Phone className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
        </div>
      </div>

      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
}

function getCountryCallingCode(country: CountryCode): string {
  const example = parsePhoneNumberFromString(`+1`, country);
  return example?.countryCallingCode || '';
}

function getCountryName(country: CountryCode): string {
  return new Intl.DisplayNames(['en'], { type: 'region' }).of(country) || country;
}