import type { Airport } from './types';

// This is a curated list of major airports worldwide
export const majorAirports: Airport[] = [
  {
    id: "jfk",
    name: "John F. Kennedy International Airport",
    city: "New York",
    country: "United States",
    iata: "JFK",
    icao: "KJFK",
    latitude: 40.6413,
    longitude: -73.7781,
    timezone: "America/New_York"
  },
  {
    id: "lhr",
    name: "London Heathrow Airport",
    city: "London",
    country: "United Kingdom",
    iata: "LHR",
    icao: "EGLL",
    latitude: 51.4700,
    longitude: -0.4543,
    timezone: "Europe/London"
  },
  {
    id: "cdg",
    name: "Charles de Gaulle Airport",
    city: "Paris",
    country: "France",
    iata: "CDG",
    icao: "LFPG",
    latitude: 49.0097,
    longitude: 2.5478,
    timezone: "Europe/Paris"
  },
  // Add more major airports as needed
];