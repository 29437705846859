import { useEffect, useState } from 'react';
import { supabase } from '../../lib/supabase/client';
import { useAuthContext } from '../../contexts/AuthContext';

interface SubscriptionPlan {
  id: string;
  name: string;
  description: string;
  price: number;
  listing_limit: number;
  airport_limit: number;
  features: string[];
}

interface Subscription {
  id: string;
  plan_id: string;
  status: 'active' | 'canceled' | 'expired';
  current_period_start: string;
  current_period_end: string;
}

export function useSubscription() {
  const { user } = useAuthContext();
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [plan, setPlan] = useState<SubscriptionPlan | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchSubscription() {
      if (!user) return;

      try {
        // Get user's profile with subscription
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('subscription_id')
          .eq('id', user.id)
          .single();

        if (profileError) throw profileError;

        if (profile?.subscription_id) {
          // Get subscription details
          const { data: sub, error: subError } = await supabase
            .from('user_subscriptions')
            .select(`
              *,
              plan:subscription_plans(*)
            `)
            .eq('id', profile.subscription_id)
            .single();

          if (subError) throw subError;

          setSubscription(sub);
          setPlan(sub.plan);
        } else {
          // Get free plan details
          const { data: freePlan, error: planError } = await supabase
            .from('subscription_plans')
            .select()
            .eq('id', 'free')
            .single();

          if (planError) throw planError;
          setPlan(freePlan);
        }
      } catch (err) {
        console.error('Error fetching subscription:', err);
        setError('Failed to load subscription details');
      } finally {
        setIsLoading(false);
      }
    }

    fetchSubscription();
  }, [user]);

  return { subscription, plan, isLoading, error };
}