import React from 'react';
import { Plane, UtensilsCrossed, Car, Building2 } from 'lucide-react';

export function ServiceIllustration() {
  const services = [
    { Icon: Plane, label: 'Flights' },
    { Icon: UtensilsCrossed, label: 'Catering' },
    { Icon: Car, label: 'Transport' },
    { Icon: Building2, label: 'Accommodation' },
  ];

  return (
    <div className="grid grid-cols-2 sm:flex sm:flex-row gap-4 sm:gap-8 justify-center max-w-lg mx-auto">
      {services.map(({ Icon, label }) => (
        <div key={label} className="flex flex-col items-center gap-2 p-4">
          <div className="p-3 sm:p-4 bg-blue-100 rounded-full">
            <Icon className="h-6 w-6 sm:h-8 sm:w-8 text-blue-600" />
          </div>
          <span className="text-sm font-medium text-gray-600">{label}</span>
        </div>
      ))}
    </div>
  );
}