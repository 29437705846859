import React from 'react';
import { AirportSearchInput } from '../../AirportSearchInput';

interface SearchBarProps {
  selectedAirport: string;
  onAirportSelect: (airportId: string) => void;
}

export function SearchBar({ selectedAirport, onAirportSelect }: SearchBarProps) {
  return (
    <div className="flex-1 max-w-3xl mx-auto w-full">
      {/* Mobile Layout */}
      <div className="lg:hidden">
        <AirportSearchInput 
          selectedAirport={selectedAirport}
          onSelect={onAirportSelect}
        />
      </div>

      {/* Desktop Layout */}
      <div className="hidden lg:block">
        <AirportSearchInput 
          selectedAirport={selectedAirport}
          onSelect={onAirportSelect}
        />
      </div>
    </div>
  );
}