import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { supabase } from '../../../../lib/supabase/client';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useSubscription } from '../../../../hooks/subscriptions/useSubscription';
import { AirportSearch } from './AirportSearch';
import { ImageUpload } from '../../../../components/ImageUpload';
import { PhoneInput } from '../../../../components/PhoneInput';
import { AddressAutocomplete } from '../../../../components/AddressAutocomplete';
import { categories } from '../../../../types/categories';
import type { CategoryId, Service } from '../../../../types';

interface ServiceFormProps {
  service?: Service;
  onClose: () => void;
  onSuccess: () => void;
}

export function ServiceForm({ service, onClose, onSuccess }: ServiceFormProps) {
  const { user } = useAuthContext();
  const { plan } = useSubscription();
  const [selectedAirports, setSelectedAirports] = useState<string[]>(
    service ? [service.airportId] : []
  );
  const [selectedCategory, setSelectedCategory] = useState<CategoryId>(
    service?.category || 'ground-transport'
  );
  const [formData, setFormData] = useState({
    title: service?.title || '',
    description: service?.description || '',
    price: service?.price.toString() || '',
    contactEmail: service?.contactEmail || '',
    contactPhone: service?.contactPhone || '',
    contactAddress: service?.contactAddress || '',
    contactWebsite: service?.contactWebsite || '',
    contactForPrice: service?.contactForPrice || false,
    images: service?.images || []
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const maxAirports = plan?.airport_limit === -1 ? Infinity : plan?.airport_limit || 1;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to create a service');
      return;
    }

    if (selectedAirports.length === 0) {
      setError('Please select at least one airport');
      return;
    }

    if (selectedAirports.length > maxAirports) {
      setError(`Your ${plan?.name} plan allows only ${maxAirports} airport${maxAirports === 1 ? '' : 's'} per service`);
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      // Create or update service
      const serviceData = {
        profile_id: user.id,
        title: formData.title,
        description: formData.description,
        category: selectedCategory,
        price: formData.contactForPrice ? 0 : parseFloat(formData.price),
        contact_email: formData.contactEmail,
        contact_phone: formData.contactPhone,
        contact_address: formData.contactAddress,
        contact_website: formData.contactWebsite,
        contact_for_price: formData.contactForPrice
      };

      let serviceId: string;

      if (service) {
        // Update existing service
        const { error: updateError } = await supabase
          .from('services')
          .update(serviceData)
          .eq('id', service.id);

        if (updateError) throw updateError;
        serviceId = service.id;

        // Delete existing airport relationships
        await supabase
          .from('service_airports')
          .delete()
          .eq('service_id', service.id);
      } else {
        // Create new service
        const { data: newService, error: createError } = await supabase
          .from('services')
          .insert(serviceData)
          .select()
          .single();

        if (createError) throw createError;
        if (!newService) throw new Error('Failed to create service');
        serviceId = newService.id;
      }

      // Create airport relationships
      const { error: airportsError } = await supabase
        .from('service_airports')
        .insert(
          selectedAirports.map(code => ({
            service_id: serviceId,
            airport_code: code
          }))
        );

      if (airportsError) throw airportsError;

      // Handle images
      if (formData.images.length > 0) {
        // Delete existing images if updating
        if (service) {
          await supabase
            .from('service_images')
            .delete()
            .eq('service_id', service.id);
        }

        // Add new images
        const { error: imagesError } = await supabase
          .from('service_images')
          .insert(
            formData.images.map((url, index) => ({
              service_id: serviceId,
              url,
              position: index
            }))
          );

        if (imagesError) throw imagesError;
      }

      onSuccess();
    } catch (err) {
      console.error('Error saving service:', err);
      setError(err instanceof Error ? err.message : 'Failed to save service');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-900">
            {service ? 'Edit Service' : 'Add New Service'}
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X size={20} className="text-gray-500" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {error && (
            <div className="bg-red-50 text-red-600 p-4 rounded-lg">
              {error}
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Service Title
            </label>
            <input
              type="text"
              value={formData.title}
              onChange={e => setFormData(d => ({ ...d, title: e.target.value }))}
              className="w-full rounded-lg border border-gray-300 px-3 py-2"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Description
            </label>
            <textarea
              value={formData.description}
              onChange={e => setFormData(d => ({ ...d, description: e.target.value }))}
              rows={4}
              className="w-full rounded-lg border border-gray-300 px-3 py-2"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Airports
              <span className="text-sm text-gray-500 ml-2">
                (Maximum {maxAirports === Infinity ? 'unlimited' : maxAirports} with your {plan?.name} plan)
              </span>
            </label>
            <AirportSearch
              selectedAirports={selectedAirports}
              onSelect={(code) => {
                setSelectedAirports(prev => 
                  prev.includes(code)
                    ? prev.filter(c => c !== code)
                    : [...prev, code]
                );
              }}
              maxSelections={maxAirports}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Category
            </label>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value as CategoryId)}
              className="w-full rounded-lg border border-gray-300 px-3 py-2 bg-white"
              required
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name} - {category.description}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-start gap-2">
            <input
              type="checkbox"
              id="contactForPrice"
              checked={formData.contactForPrice}
              onChange={e => setFormData(d => ({ ...d, contactForPrice: e.target.checked }))}
              className="mt-1"
            />
            <label htmlFor="contactForPrice" className="text-sm text-gray-700">
              Contact for pricing
            </label>
          </div>

          {!formData.contactForPrice && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Price
              </label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">$</span>
                <input
                  type="number"
                  value={formData.price}
                  onChange={e => setFormData(d => ({ ...d, price: e.target.value }))}
                  className="w-full pl-8 rounded-lg border border-gray-300 px-3 py-2"
                  min="0"
                  step="0.01"
                  required
                />
              </div>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Contact Email
            </label>
            <input
              type="email"
              value={formData.contactEmail}
              onChange={e => setFormData(d => ({ ...d, contactEmail: e.target.value }))}
              className="w-full rounded-lg border border-gray-300 px-3 py-2"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Contact Phone
            </label>
            <PhoneInput
              value={formData.contactPhone}
              onChange={value => setFormData(d => ({ ...d, contactPhone: value }))}
              className="w-full rounded-lg border border-gray-300 px-3 py-2"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Address
            </label>
            <AddressAutocomplete
              value={formData.contactAddress}
              onChange={address => setFormData(d => ({ ...d, contactAddress: address }))}
              className="w-full rounded-lg border border-gray-300 px-3 py-2"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Website
            </label>
            <input
              type="url"
              value={formData.contactWebsite}
              onChange={e => setFormData(d => ({ ...d, contactWebsite: e.target.value }))}
              className="w-full rounded-lg border border-gray-300 px-3 py-2"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Images
            </label>
            <ImageUpload
              images={formData.images}
              onChange={urls => setFormData(d => ({ ...d, images: urls }))}
            />
          </div>

          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 disabled:opacity-50"
            >
              {isSubmitting ? 'Saving...' : service ? 'Save Changes' : 'Create Service'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}