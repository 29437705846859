import { useState } from 'react';
import { stripe } from '../../lib/stripe/client';
import { supabase } from '../../lib/supabase/client';
import { useAuthContext } from '../../contexts/AuthContext';

export function useSubscribeToStripe(planId: 'basic' | 'pro' | 'enterprise') {
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubscribe = async () => {
    if (!user) {
      window.location.href = '/signin?redirect=/pricing';
      return;
    }

    setIsLoading(true);
    setError(null);
    
    try {
      // Create checkout session
      const { data, error: functionError } = await supabase.functions.invoke(
        'create-checkout-session',
        {
          body: { 
            planId,
            userId: user.id
          }
        }
      );

      if (functionError) throw functionError;
      if (!data?.sessionId) throw new Error('No session ID returned');

      // Redirect to Stripe checkout
      const stripeInstance = await stripe;
      if (!stripeInstance) throw new Error('Failed to load Stripe');

      const { error: stripeError } = await stripeInstance.redirectToCheckout({
        sessionId: data.sessionId
      });

      if (stripeError) throw stripeError;
    } catch (err) {
      console.error('Subscription error:', err);
      setError('Failed to start checkout process. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSubscribe, isLoading, error };
}