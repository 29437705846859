import React from 'react';
import { Check } from 'lucide-react';
import { SubscribeButton } from '../../../components/subscribe/SubscribeButton';
import type { PricingPlan } from '../../../types';

interface PricingCardProps {
  plan: PricingPlan;
}

export function PricingCard({ plan }: PricingCardProps) {
  const isFreePlan = plan.id === 'free';

  return (
    <div 
      className={`
        relative bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all duration-300 hover:scale-[1.02] hover:shadow-2xl
        ${plan.popular ? 'ring-2 ring-primary-500' : ''}
        ${isFreePlan ? 'border border-gray-200' : ''}
      `}
    >
      {plan.popular && (
        <div className="absolute top-0 right-0 bg-primary-500 text-white px-4 py-1 rounded-bl-lg text-sm font-medium">
          Most Popular
        </div>
      )}

      <div className="p-8">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-2xl font-bold text-gray-900">{plan.name}</h3>
          <div className="flex items-baseline">
            <span className="text-4xl font-bold text-gray-900">${plan.price}</span>
            <span className="ml-1 text-gray-500">/mo</span>
          </div>
        </div>
        
        <p className="text-gray-600 mb-8">{plan.description}</p>

        <SubscribeButton 
          planId={plan.id as 'basic' | 'pro' | 'enterprise'} 
          className={`
            w-full py-3 px-6 rounded-lg font-semibold transition-all
            ${plan.popular 
              ? 'bg-primary-500 hover:bg-primary-600 text-white shadow-lg hover:shadow-xl' 
              : isFreePlan
                ? 'bg-gray-100 hover:bg-gray-200 text-gray-900'
                : 'bg-gray-900 hover:bg-gray-800 text-white'
            }
          `}
        />

        <div className="mt-8 space-y-4">
          {plan.features.map((feature) => (
            <div key={feature} className="flex items-start gap-3">
              <Check className={`h-5 w-5 flex-shrink-0 ${plan.popular ? 'text-primary-500' : 'text-gray-500'}`} />
              <span className="text-gray-600">{feature}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}