import React from 'react';
import { MetaTags } from '../../components/seo/MetaTags';

export function PrivacyPage() {
  return (
    <>
      <MetaTags
        title="Privacy Policy - AirPortal"
        description="Learn about how AirPortal collects, uses, and protects your personal information."
      />

      <main className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
          
          <div className="bg-white rounded-xl shadow-sm p-8 space-y-8">
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Introduction</h2>
              <p className="text-gray-600">
                At AirPortal, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website and services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Information We Collect</h2>
              <div className="space-y-4">
                <h3 className="text-lg font-medium text-gray-900">Personal Information</h3>
                <p className="text-gray-600">
                  We collect personal information that you voluntarily provide to us when you:
                </p>
                <ul className="list-disc list-inside text-gray-600 ml-4 space-y-2">
                  <li>Register for an account</li>
                  <li>Express interest in obtaining information about our services</li>
                  <li>Participate in activities on the website</li>
                  <li>Contact us with inquiries</li>
                </ul>
                <p className="text-gray-600">
                  This information may include your name, email address, phone number, business information, and payment details.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">How We Use Your Information</h2>
              <p className="text-gray-600 mb-4">
                We use the information we collect to:
              </p>
              <ul className="list-disc list-inside text-gray-600 ml-4 space-y-2">
                <li>Provide, operate, and maintain our website and services</li>
                <li>Improve, personalize, and expand our website and services</li>
                <li>Understand and analyze how you use our website</li>
                <li>Develop new products, services, features, and functionality</li>
                <li>Communicate with you about our services</li>
                <li>Process your transactions</li>
                <li>Prevent fraudulent transactions and monitor against theft</li>
                <li>Send you marketing and promotional communications (with your consent)</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Information Sharing</h2>
              <p className="text-gray-600">
                We may share your information with third parties in certain situations:
              </p>
              <ul className="list-disc list-inside text-gray-600 ml-4 space-y-2">
                <li>Business partners and service providers who assist us in operating our website and services</li>
                <li>Law enforcement or government agencies when required by law</li>
                <li>Other users when you choose to make your profile or listings public</li>
                <li>In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Security</h2>
              <p className="text-gray-600">
                We implement appropriate technical and organizational security measures to protect your information. However, please note that no security system is impenetrable and we cannot guarantee the security of our systems 100%.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Your Rights</h2>
              <p className="text-gray-600 mb-4">
                You have certain rights regarding your personal information:
              </p>
              <ul className="list-disc list-inside text-gray-600 ml-4 space-y-2">
                <li>Access your personal information</li>
                <li>Correct inaccurate information</li>
                <li>Request deletion of your information</li>
                <li>Object to our use of your information</li>
                <li>Withdraw consent where previously provided</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Contact Us</h2>
              <p className="text-gray-600">
                If you have questions about this Privacy Policy or our practices, please contact us at privacy@airportal.com.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Updates to This Policy</h2>
              <p className="text-gray-600">
                We may update this privacy policy from time to time. The updated version will be indicated by an updated "Last updated" date and the updated version will be effective as soon as it is accessible.
              </p>
            </section>

            <div className="text-sm text-gray-500 pt-8 border-t">
              Last updated: January 7, 2025
            </div>
          </div>
        </div>
      </main>
    </>
  );
}